.ecButton {

    box-shadow: 0px 3px 3px -2px #575756;
    background-color: #f67a1b;
    border-radius: 28px;
    border: 1px solid #ede5d8;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    font-family: "Josefin Sans";
    padding: 10px 24px;
    text-decoration: none;
    text-shadow: 0px 0px 0px #ffffff;
    text-align: center;

}

.ecButton:hover {
    background-color: #d33e84;
    transform: scale(1.05);
}



@media (max-width: 500px) {


    .ecButton {
        font-size: 17px;
        padding: 7px 14px;
    }
}