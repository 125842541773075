.footerContainer {
    width: 100%;
    padding: 5px 20px;
    background-color: transparent;
    align-items: center;
    text-align: center;
}

.link_underline {
    text-decoration: none;
}

.footerText {
    color: whitesmoke;
    font-family: "Josefin Sans";
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    word-spacing: 1px;
    margin-bottom: 7px;
    display: inline;
    padding: 0px 2px;
}

.footerText:hover {
    color: #e99656;
}

.reservados {
    color: #464E57;
    font-family: "Josefin Sans";
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    word-spacing: 1px;
    margin-bottom: 7px;
    display: inline;
    padding: 0px 12px;
}

.iconContainer {
    padding: 8px 8px;
    margin-inline: 10px;
    align-items: center;
    text-align: center;
    align-self: center;
    background-color: #F6791B;
}

.iconContainer:hover>.icon {
    color: #F6791B;
}

.icon {
    color: #464E57;
    font-size: 1.1em;
}