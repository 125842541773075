.cardImage {
    width: 100%;
    height: 350px;
    margin: 0px;
    padding: 10px 17px;
    object-fit: contain;
    object-position: center;
    border-radius: 30px;
}

.inlineSection {
    display: inline-flex;
    align-items: baseline;
}

.card {
    z-index: 2;
    background-color: white;
    min-height: 385px;
    border-radius: 30px;
    box-shadow: 5px 4px 12px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
    transition: all .2s ease-in-out;
}


.card:hover {
    /* background-color: #f2eeeb;*/
    transform: scale(1.02);
}

.title {
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: left;
    font-weight: 700;
    word-spacing: 0.5px;
    color: #2E384D;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
}

.cardBody {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    font-weight: 500;
    word-spacing: -2px;
    color: #8C9CB1;
    margin: 3px 0px;
}

.cardLink {
    font-family: "Josefin Sans";
    font-size: 15px;
    text-align: left;
    font-weight: 700;
    word-spacing: 0px;
    color: #FDA307;
    margin: 10px 0px;
}

.cardLink:hover {
    text-decoration: underline 2.5px;

}

.icon_image {
    width: 30px;
    height: 50px;
    margin: 0px;
    padding: 0px 0px;
    object-fit: contain;
    object-position: bottom;
    border-radius: 0px;
    padding-right: 6px;
}

.cardContent {
    padding: 16px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    .cardContent {
        padding: 32px 32px 0px 32px;
    }
}