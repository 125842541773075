.header_section {
    padding: 0px 0px;
    margin: 0px;
    position: relative;
}

.text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.text_container_alt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 500px;
    background: url(../../../public/assets/landing_large.png) no-repeat center center;
    background-size: cover;
}

.textTittle {
    font-family: "Josefin Sans";
    font-size: 45px;
    text-align: center;
    font-weight: 700;
    word-spacing: 1px;
    color: #EBF1F4;
    /*F4E9E1  EBF1F4 */
    margin-bottom: 7px;
}

.textDescription {
    text-align: center;
    border-radius: 10px;
    padding: 0px 0px;
    padding-bottom: 10px;
    font-family: "Josefin Sans";
    font-size: 1.07em;
    color: #EBF1F4;
    margin: 0 auto;
    width: 80%;
}

.img_container {

    object-fit: contain;
    object-position: center;
}


.landing_img {
    border-radius: 20px;
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    background-color: transparent;
    float: left;
}


.ecButton {
    margin-top: 20px;
    box-shadow: 0px 3px 3px -2px #575756;
    background-color: #f67a1b;
    border-radius: 28px;
    border: 1px solid #ede5d8;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    font-family: "Josefin Sans";
    padding: 10px 24px;
    text-decoration: none;
    text-shadow: 0px 0px 0px #ffffff;

}

.ecButton:hover {
    background-color: #d33e84;
    transform: scale(1.05);
}



@media (max-width: 1000px) {

    .header_description {
        font-size: 0.88em;
    }
}

@media (max-width: 500px) {


    .ecButton {
        font-size: 17px;
        padding: 7px 14px;
    }

    .img_container {
        display: none;

    }

    .text_container {
        height: 450px;
        padding-left: 50px;
        padding-right: 50px;
    }

}