:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #8C9CB1;
}

.flex_card>h2 {
    color: var(--grey);
    font-family: var(--textFamily);
    font-size: 22px;
    margin: 10px 0px 4px 0px;
    letter-spacing: 0.5px;
}

.flex_card>p {
    color: var(--lightGrey);
    font-family: var(--textFamily);
    font-size: 16px;
    letter-spacing: 0.3px;
}


.icon_image {
    width: 38px;
    height: 50px;
    margin: 0px;
    padding: 0px;
    object-fit: contain;
    object-position: center;
}

.flex_card {
    width: calc(33.0% - 12px);
    min-height: 250px;
    background-color: var(--lightOrangeColor);
    box-shadow: 10px 12px 43px 4px rgba(61, 61, 61, 0.1);
    transition: all .2s ease-in-out;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex_card:hover {
    transform: scale(1.02);
    background-color: #F5A76C;
}

.flex_card:hover>h2 {
    color: white;
}

.flex_card:hover>p {
    color: whitesmoke;
}


@media (max-width: 850px) {
    .flex_card {
        width: calc(50.0% - 25px);
    }
}

@media (max-width: 500px) {
    .flex_card {
        width: calc(100.0% - 25px);
    }
}