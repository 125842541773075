h3 {
    font-family: "Josefin Sans";
    font-size: 24px;
    text-align: left;
    font-weight: 700;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 10px;

}

p {
    font-family: "Josefin Sans";
    font-size: 16px;
    text-align: left;

    font-weight: 500;
    margin: 0px;
    color: #8C9CB1;
    padding-bottom: 5px;
}

.grid_container {
    margin: 20px;
}

.navbar {
    height: 65px;
    width: 100%;
    background-color: #EBF1F4;
}

.body {
    margin-top: 25px;
    margin-right: 10%;
    margin-left: 10%;
    background-color: #EBF1F4;
    /*border-style: solid;
    border-width: 2px;*/
}

.img {
    padding: 30px 20px;
    width: 100%;
    height: 60vh;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}

.img_header {
    padding: 30px 20px;
    width: 100%;
    height: 60vh;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}

.header_Content {
    display: flex;
    flex-direction: column;
    height: 60vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: transparent;
}

.header_Content_2 {

    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    padding: 15px;
    background-color: transparent;
}

.text {
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    font-weight: 700;
}

.subtitle {
    font-family: "Josefin Sans";
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}

.button {
    margin-top: 7px;
    box-shadow: 0px 3px 3px -2px #575756;
    background-color: #f67a1b;
    border-radius: 28px;
    border: 1px solid #ede5d8;
    cursor: pointer;
    color: #ffffff;
    font-size: 18px;
    font-family: "Josefin Sans";
    padding: 10px 24px;
    text-decoration: none;
    text-shadow: 0px 0px 0px #ffffff;

}

.button:hover {
    background-color: #d33e84;
}

.benefit_sections {
    padding: 20px;

    background-color: #F4E9E1;
    box-shadow: 10px 12px 43px 4px rgba(0, 0, 0, 0.14);
    transition: all .2s ease-in-out;
}

.benefit_sections:hover {
    background-color: #F6791B;
    transform: scale(1.05);
}

.benefit_sections:hover>h3 {
    color: white;
}

.benefit_sections:hover>p {
    color: whitesmoke;
}

.icon_image {
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0px 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 0px;
}

@media (max-width: 1240px) {
    .body {
        margin-right: 8%;
        margin-left: 8%;
    }
}

@media (max-width: 500px) {
    .body {
        margin-right: 15px;
        margin-left: 15px;
    }

    .img_header {
        display: none;
    }

    .header_Content {
        background-image: url("../../public/assets/keeper/landing1.png");
        background-size: contain;
        background-repeat: no-repeat;
    }


}