:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}

.container {
    display: flex;
    flex-direction: column;
    height: 500px;
    background: url(../../../public/assets/services/backHeader.png) no-repeat center center;
    /*//fixed*/
    background-size: cover;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
}

.container h2 {
    font-family: "Josefin Sans";
    font-size: 26px;
    text-align: left;
    font-weight: 700;
    color: #EBF1F4;
}

.container p {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    color: #EBF1F4;

    font-weight: 300;
}

.textWrapper {
    width: 65%;

}

.content h3 {
    font-family: "Josefin Sans";
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    color: var(--grey);
}

.content p {
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: left;
    color: var(--lightGrey);

    font-weight: 300;
}

/*
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: left;
    font-weight: 700;
    word-spacing: 0.5px;
    color: #2E384D;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
*/
.img {
    padding: 30px 20px;
    width: 100%;
    height: 400px;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}

.content {
    padding: 2% 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contentColor {
    color: var(--orange);
    font-family: "Josefin Sans";
    font-size: 13px;
    text-align: left;

    font-weight: 700;

    margin: 0%;
}

.contacus {
    background-color: var(--lightOrangeColor);
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding: 2% 4%;
}

.contacus>h1 {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: left;

    font-weight: 700;
    margin: 0%;
    padding: 3% 0%;
}


@media (max-width: 850px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 6%;
    }

    .contacus {

        grid-template-columns: 1fr;
    }

}

@media (max-width: 500px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 4%;
    }
}