.iconContainer {
    margin-inline: 1px;

    align-items: center;
    text-align: center;
    align-self: center;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon {
    color: #F6791B;
}

.iconContainer:hover>.icon {
    color: #D33E84;
}


.text_button {
    display: block;
}

.text_button:hover {
    color: #F6791B;
}


.wrapper {
    padding-right: 10%;
    padding-left: 10%;
}

.countryFlag {

    padding-bottom: 0px;
    margin: 0px;
    padding-left: 10px;
}

@media (max-width: 1240px) {
    .wrapper {
        padding-right: 5%;
        padding-left: 5%;
    }
}

@media (max-width: 500px) {
    .wrapper {
        padding-right: 0px;
        padding-left: 0px;
    }

}