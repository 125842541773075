.wrapper {
    padding-right: 10%;
    padding-left: 10%;
}

.body {
    padding-right: 0px;
    padding-left: 0px;

}

@media (max-width: 1240px) {
    .wrapper {
        padding-right: 5%;
        padding-left: 5%;
    }
}

@media (max-width: 500px) {
    .wrapper {
        padding-right: 0px;
        padding-left: 0px;
    }

    .heading {
        font-size: 1.5em;
    }
}