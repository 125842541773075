:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #8C9CB1;
}

.columnContainer>h2 {
    color: var(--grey);
    font-family: var(--textFamily);
    font-size: 17px;
    margin: 10px 0px 4px 0px;
    letter-spacing: 0.5px;
}

.columnContainer>p {
    color: var(--lightGrey);
    font-family: var(--textFamily);
    font-size: 14.5px;
    letter-spacing: 0px;
}

.icon_image {
    width: 55px;
    min-height: 180px;
    margin: 0px;
    padding: 0px;
    object-fit: contain;
    object-position: center;
    margin-right: 15px;
}

.flex_card {
    width: calc(33.0% - 12px);
    min-height: 210px;
    background-color: white;
    box-shadow: 10px 12px 43px 4px rgba(61, 61, 61, 0.05);
    transition: all .2s ease-in-out;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
}

.flex_card:hover {
    transform: scale(1.02);
    background-color: #F5A76C;
    text-decoration: none;
}

.flex_card:hover>.columnContainer>h2 {
    color: white;
}

.flex_card:hover>.columnContainer>p {
    color: whitesmoke;
}



.columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.link {
    color: var(--lightOrangeColor);
    display: none;
    font-family: var(--textFamily);
    font-size: 13px;

}

.flex_card:hover>.columnContainer>.link {
    display: block;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .flex_card {
        min-height: 255px;
    }
}

@media (max-width: 850px) {
    .flex_card {
        width: calc(50.0% - 25px);
    }
}

@media (max-width: 500px) {
    .flex_card {
        width: calc(100.0% - 25px);
        min-height: 210px;
    }
}