:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #8C9CB1;
}

.container {
    display: flex;
    flex-direction: column;
    height: 570px;
    background: url(../../public/assets/services/header.png) no-repeat center center;
    /*//fixed*/
    background-size: cover;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
}

.textWrapper {
    width: 65%;

}

.container h2 {
    font-family: "Josefin Sans";
    font-size: 26px;
    text-align: left;
    font-weight: 700;
    color: #EBF1F4;
}

.container p {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    color: #EBF1F4;

    font-weight: 300;
}


.contentWrapper {
    margin: 0 auto;
    width: 80%;
}


.flex_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
}

.flex_techcontainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.headerText {
    font-family: "Josefin Sans";
    font-size: 27px;
    text-align: center;
    color: var(--grey);

    font-weight: 500;
}


@media (max-width: 1240px) {
    .contentWrapper {
        width: 85%;
    }
}

@media (max-width: 500px) {
    .contentWrapper {
        width: 85%;
    }

}