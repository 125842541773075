.flex_card {
    width: calc(14.2% - 12px);
    background-color: transparent;
    /* box-shadow: 10px 12px 43px 4px rgba(61, 61, 61, 0.05);*/
    transition: all .2s ease-in-out;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    border-style: none;
    cursor: pointer;
}

.modal_content {

    display: flex;
    flex-direction: column;

}

.modal_close_button {
    margin: 0 auto;
    min-width: 15%;

    box-shadow: 0px 3px 3px -2px #575756;
    background-color: #f67a1b;
    border-radius: 28px;
    border: 1px solid #ede5d8;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    font-family: "Josefin Sans";
    padding: 10px 20px;
    text-decoration: none;
    text-shadow: 0px 0px 0px #ffffff;
    text-align: center;
}

.modal_close_button:hover {
    background-color: #d33e84;
}

.icon_image {
    width: 100%;
    filter: grayscale(100%);
}

.flex_card:hover .icon_image {
    filter: grayscale(20%);
}

.modal {
    background-color: 'rgba(255, 255, 255, 0.75)';
    position: 'fixed';

}

.modal_content {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin: 40px;
}

@media (max-width: 1900px) {
    .flex_card {
        width: calc(16.5% - 12px);
    }
}

@media (max-width: 1600px) {
    .flex_card {
        width: calc(20.0% - 12px);
    }
}


@media (max-width: 850px) {
    .flex_card {
        width: calc(30.0% - 15px);
    }
}

@media (max-width: 500px) {
    .flex_card {
        width: calc(50.0% - 15px);
    }
}