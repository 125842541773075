.container {
    /*
     display: flex;
    justify-content: center;
    align-items: center;
    */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;

}

.image {
    width: 100%;
    height: 28vh;
    margin: 0px;
    padding: 5px 10px;
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
}

.text {
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    word-spacing: 1px;
    color: #2E384D;
    margin-bottom: 0px;
}

@media (max-width: 500px) {
    .text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

}