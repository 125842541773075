:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}

.container {
    display: flex;
    flex-direction: column;
    height: 500px;
    background: url(../../../public/assets/services/testLanding.png) no-repeat center center;
    background-size: cover;
    /*//fixed*/
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
}

.container h2 {
    font-family: "Josefin Sans";
    font-size: 26px;
    text-align: left;
    font-weight: 700;
    color: #EBF1F4;
}

.container p {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    color: #EBF1F4;

    font-weight: 300;
}

.textWrapper {
    width: 65%;

}

.content h3 {
    font-family: "Josefin Sans";
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    color: var(--grey);
}

.content p {
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: left;
    color: var(--lightGrey);

    font-weight: 300;
}

/*
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: left;
    font-weight: 700;
    word-spacing: 0.5px;
    color: #2E384D;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
*/
.img {
    padding: 30px 20px;
    width: 100%;
    height: 400px;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}

.content {
    padding: 2% 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contentColor {
    color: var(--orange);
    font-family: "Josefin Sans";
    font-size: 13px;
    text-align: left;

    font-weight: 700;

    margin: 0%;
}

.contacus {
    background-color: var(--lightOrangeColor);
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding: 2% 4%;
}

.contacus>h1 {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: left;

    font-weight: 700;
    margin: 0%;
    padding: 3% 0%;
}


/* Seccion de cards para el testing */
.title_text {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    margin: 0%;
    padding: 15px 20px;
    padding-top: 0px;
    width: 100%;
}

.text_header {
    color: var(--lightGrey);
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin: 0 auto;
    padding: 0px 20px;
    padding-top: 0px;
    padding-bottom: 28px;
    width: 80%;
}

.grid_container_test {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
}

.grid_container_test h3 {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    margin: 0%;
    padding: 0px 10px;
    width: 100%;
    border-left: 4px solid #d33e84;
    margin-bottom: 12px;
}

.grid_container_test p {
    color: var(--lightGrey);
    font-family: "Josefin Sans";
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    margin: 0%;
    padding: 0px 20px;
}

.grid_card_test {
    min-height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: white;
    box-shadow: 1px 2px 5px 0px #bcc2c9;
    transition: all .2s ease-in-out;
}

.grid_card_test:hover {
    transform: scale(1.02);
}

.grid_card_test:hover h3 {
    border-left: 4px solid #FDA307;
}



@media (max-width: 850px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 6%;
    }

    .contacus {
        grid-template-columns: 1fr;
    }

    .grid_container_test {
        grid-template-columns: 1fr 1fr;
    }

}

@media (max-width: 500px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 4%;
    }

    .grid_container_test {
        grid-template-columns: 1fr;
    }

    .grid_card_test {
        min-height: 210px;
    }
}