:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}

.container {
    display: flex;
    flex-direction: column;
    height: 500px;
    background: url(../../../public/assets/services/webLanding.png) repeat center center;
    background-size: cover;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
}

.container h2 {
    font-family: "Josefin Sans";
    font-size: 26px;
    text-align: left;
    font-weight: 700;
    color: #EBF1F4;
}

.container p {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    color: #EBF1F4;

    font-weight: 300;
}

.textWrapper {
    width: 65%;

}

.content h3 {
    font-family: "Josefin Sans";
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    color: var(--grey);
}

.content p {
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: left;
    color: var(--lightGrey);

    font-weight: 300;
}

.img {
    padding: 30px 20px;
    width: 100%;
    height: 400px;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}

.content {
    padding: 2% 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contentColor {
    color: var(--orange);
    font-family: "Josefin Sans";
    font-size: 13px;
    text-align: left;

    font-weight: 700;

    margin: 0%;
}

.contacus {
    background-color: var(--lightOrangeColor);
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding: 2% 4%;
}

.contacus>h1 {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: left;

    font-weight: 700;
    margin: 0%;
    padding: 3% 0%;
}


/* Sqipper class */
.title {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    margin: 0%;
    padding: 10px 0%;
}

.swipperContainer {
    margin: 0 auto;
    width: 80%;
    padding-top: 25px;
    padding-bottom: 25px;
}

.swiper {
    height: 380px;
    padding-right: 27px;
}

.swiper_slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    padding: 1px;
}

.swiper_content {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 91%;
    border-style: solid;
    border-color: #edeeee;
    box-shadow: 0px 1px 4px 0px #768394;
    border-width: 0.2px;
}

.carrousel_img {
    display: block;
    height: 80px;
    object-fit: cover;
}


.swipperContainer h2 {
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: left;
    color: var(--grey);

    font-weight: 700;
    text-align: center;
    padding: 0px 25px;
}

.swipperContainer p {
    font-family: "Josefin Sans";
    font-size: 18px;
    text-align: left;
    color: var(--lightGrey);
    text-align: center;
    font-weight: 300;
    padding: 0px 25px;
}

.carrouselContainer {
    width: 100%;
    height: 90%;
    border-style: solid;
    border-color: #F8F8F8;
    border-width: 0.02px;
    padding-bottom: 20px;

    padding-top: 15px;

    box-shadow: 0px 0px 3px -1px #bfc1c4;
    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #F8F8F8;
    border-radius: 5px;

}


@media (max-width: 850px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 6%;
    }

    .contacus {

        grid-template-columns: 1fr;
    }

    .swipperContainer {
        width: 90%;
    }

}

@media (max-width: 500px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 4%;
    }
}