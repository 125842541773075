:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}


.container {
    padding: 5px 20px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.textRow {
    padding-bottom: 4px;
    display: flex;
    align-items: baseline;
}

.textRow>h2 {
    color: var(--grey);
    margin: 0px;
    padding-right: 4px;
    font-size: 16px;
    font-family: var(--textFamily);
}

.textRow>p {
    color: var(--lightGrey);
    font-family: var(--textFamily);
    font-size: 16px;
    padding-right: 20px;
}