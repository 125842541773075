.layout {
    background-color: #EBF1F4;
}

.header {

    height: 400px;
    background-image: url("../../public/assets/mobile/landing.png");
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_header {
    padding: 20px 20px;
    width: 100%;

    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;
}


.tittle_container {
    background-color: rgba(252, 252, 252, 0.5);
    padding: 0px 15px;
    border-radius: 12px;
}

.content {
    display: flex;
    flex-direction: column;
    height: 600px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    padding: 25px;
    margin: 0px;
}



@media (max-width: 500px) {
    .img_header {
        display: none;
    }

}