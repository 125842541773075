:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}

.container {
    display: flex;
    flex-direction: column;
    height: 500px;
    background: url(../../../public/assets/services/appHeader.png) no-repeat center center;
    background-size: cover;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
}

.container h2 {
    font-family: "Josefin Sans";
    font-size: 26px;
    text-align: left;
    font-weight: 700;
    color: #EBF1F4;
}

.container p {
    font-family: "Josefin Sans";
    font-size: 17px;
    text-align: left;
    color: #EBF1F4;
    font-weight: 300;
}

.textWrapper {
    width: 65%;

}

.content h3 {
    font-family: "Josefin Sans";
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    color: var(--grey);
}

.content p {
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: left;
    color: var(--lightGrey);

    font-weight: 300;
}

/*
    font-family: "Josefin Sans";
    font-size: 22px;
    text-align: left;
    font-weight: 700;
    word-spacing: 0.5px;
    color: #2E384D;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
*/
.img {
    padding: 30px 20px;
    width: 100%;
    height: 400px;
    margin: 0px;
    object-fit: contain;
    object-position: center;
    border-radius: 35px;

}

.iconimg {
    width: 100%;
    height: 85px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 12px;
}


.content {
    padding: 2% 10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contentColor {
    color: var(--orange);
    font-family: "Josefin Sans";
    font-size: 13px;
    text-align: left;
    font-weight: 700;
    margin: 0%;
}

.contacus {
    background-color: var(--lightOrangeColor);
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    padding: 2% 4%;
}

.contacus>h1 {
    color: var(--grey);
    font-family: "Josefin Sans";
    font-size: 25px;
    text-align: left;

    font-weight: 700;
    margin: 0%;
    padding: 3% 0%;
}

.appprocessdevelopment {
    background: url(../../../public/assets/services/appcycle.png) repeat center center;
    padding: 2% 10%;
    background-size: cover;
}

/* Section for app life process */
.sectionapplifecycle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.columnlifeapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 14%;

}

.appprocessdevelopment p {
    font-family: "Josefin Sans";
    font-size: 16px;
    text-align: center;
    color: #EBF1F4;
    font-weight: 500;
}

.appprocessdevelopment h3 {
    font-family: "Josefin Sans";
    font-size: 24px;
    text-align: center;
    color: #EBF1F4;
    font-weight: 500;
}

.appprocessdevelopment h2 {
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    color: #EBF1F4;
    font-weight: 500;
}

/* Section for small cards IOS Android */
.cardSectionContainer {
    padding: 2% 10%;
}

.cardSectionContainer>h2 {
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    color: var(--grey);
    font-weight: 500;
}

.cardSectionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cardsmall {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 30px;
    border-style: solid;
    border-color: #edeeee;
    box-shadow: 0px 0px 4px 0px #768394;
    border-width: 0.2px;
    padding: 10px 30px;
    border-radius: 6px;

}

.cardsmall>h3 {
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: center;
    color: var(--orange);
    font-weight: 500;
}

.cardsmall>p {
    font-family: "Josefin Sans";
    font-size: 16px;
    text-align: center;
    color: var(--lightGrey);
    font-weight: 300;
}


@media (max-width: 880px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 6%;
    }

    .cardSectionContainer {
        padding: 2% 6%;
    }

    .contacus {
        grid-template-columns: 1fr;
    }

    .sectionapplifecycle {
        grid-template-columns: 1fr 1fr;
    }

}

@media (max-width: 500px) {
    .content {
        grid-template-columns: 1fr;
        padding: 2% 4%;
    }

    .cardSectionContainer {
        padding: 2% 4%;
    }

    .sectionapplifecycle {
        grid-template-columns: 1fr;
    }

    .cardSectionGrid {
        grid-template-columns: 1fr;
    }
}