.img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    background-size: cover;
    margin: 0px;
    padding: 0px;
}

.wrapper {
    margin: 0 auto;
    width: 80%;
}

.content {
    width: 100%;
    background-color: #EBF1F4;
    margin: -10px 0px;
    padding: 0px;
    padding-bottom: 50px;
    padding-top: 20px;
}


.textHeader {
    color: #464E57;
    margin: 0px;
    padding: 10px 0px;
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: left;
    font-weight: 700;
    word-spacing: 1px;
}

.textContent {
    margin: 0px;
    color: #464E57;
    font-family: "Josefin Sans";
    font-size: 20px;
    text-align: left;
    font-weight: 300;
}

.textSubtitle {
    margin: 0px;
    padding: 17px 0px 10px 0px;
    color: #464E57;
    font-family: "Josefin Sans";
    font-size: 24px;
    text-align: left;
    font-weight: 700;
}

@media (max-width: 1240px) {
    .wrapper {
        width: 90%;
    }
}


@media (max-width: 500px) {
    .img {
        object-fit: cover;
    }

    .wrapper {
        width: 90%;
    }
}