.heading {
    text-align: center;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 12px;
    padding: 0px;
    font-family: "Josefin Sans";
    font-size: 1.8em;
    font-weight: 700;
    word-spacing: 1px;
    color: #2E384D;
}