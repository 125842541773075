:root {
    --textFamily: "Josefin Sans";
    --titleSize: 26px;
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
}

.wrapper {
    margin: 0 auto;
    width: 80%;
}

.header {
    /*  height: 60vh;*/
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;


}

.header_image {
    /*    width: 101%;*/
    position: relative;
    object-fit: fill;
    object-position: center;
    top: 0px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    border-radius: 10px;

}

.headerText {
    position: absolute;
    bottom: 20%;
    left: 10%;
    font-family: var(--textFamily);
    font-size: 35px;
    color: #464E57;
    font-weight: 700;
    padding: 10px 15px;

    background-color: var(--lightOrangeColor);
    padding: 3px 8px;
    margin: 0px;
    border-radius: 12px;
}

.title {
    font-family: "Josefin Sans";
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    word-spacing: 1px;

}

/*
.solution_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.card {
    width: 100%;
    height: 100px;
    background-color: blueviolet;
}
*/

/* 5 Cards section */
.flex_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.flex_card {
    width: calc(33.0% - 25px);
    height: 200px;
    background-color: var(--lightOrangeColor);
    box-shadow: 10px 12px 43px 4px rgba(61, 61, 61, 0.1);
    transition: all .2s ease-in-out;
    padding: 10px 20px;
}

.icon_image {
    width: 38px;
    height: 50px;
    margin: 0px;
    padding: 0px;
    object-fit: contain;
    object-position: center;
}


.flex_card:hover {
    transform: scale(1.02);
}



/* Informative section */
.container {
    margin: 0 auto;
    width: 100%;
}

.header_text {
    padding: 10px;
    text-align: center;

    font-family: var(--textFamily);
    font-size: var(--titleSize);
}

.section_container {
    margin: 0px 0px 0px 0px;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 2fr;
}

.testCard {
    height: 240px;
    background-color: cadetblue;
}

.section_container2 {
    margin: 0px 0px 0px 0px;
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr 1fr;
}


.card_image {
    height: 240px;
    background-color: rgb(170, 60, 161);
}



.image {
    width: 100%;
    height: 40vh;
    margin: 0px;
    padding: 10px 10px;
    object-fit: contain;
    object-position: center;
    border-radius: 30px;
}


@media (max-width: 850px) {
    .flex_card {
        width: calc(50.0% - 25px);
        background-color: gold;
    }

    .container {
        width: 100%;
    }

    .wrapper {

        width: 90%;
    }





    .section_container {
        grid-template-columns: 1fr 1fr;
    }

    .section_container2 {
        grid-template-columns: 1fr 1fr;
    }

    .headerText {
        bottom: 14%;
        left: 14%;
        font-size: 28px;

    }

    .image {
        object-fit: cover;
    }
}



@media (max-width: 500px) {
    .header_image {
        width: 100%;
    }

    .section_container {
        grid-template-columns: 1fr;
    }

    .section_container2 {
        grid-template-columns: 1fr;
    }

    .flex_card {
        width: calc(100.0% - 25px);
        background-color: rgb(92, 87, 58);
    }

    .container {
        width: 100%;
    }



    .card_image {
        display: none;
    }

    .headerText {
        bottom: 8%;
        left: 8%;
        font-size: 23px;
    }

    .image {
        display: none;
    }

    .wrapper {

        width: 100%;
    }
}