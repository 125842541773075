:root {
    --textFamily: "Josefin Sans";
    --lightOrangeColor: #F4E9E1;
    --orange: #FDA307;
    --pink: #d33e84;
    --grey: #2E384D;
    --lightGrey: #768394;
}

.section_wrapper {
    margin: 0 auto;
    width: 80%;
}

.section_wrapper h2 {
    font-size: 28px;
    text-align: center;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0px;
    font-family: "Josefin Sans";
    font-weight: 700;
    color: var(--orange);
}

.section_wrapper p {
    font-size: 16px;
    text-align: center;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px 20px;
    font-family: "Josefin Sans";
    font-weight: 500;
    color: var(--lightGrey);
}

.section_wrapper h3 {
    font-size: 24px;
    text-align: center;
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 0px;
    font-family: "Josefin Sans";
    font-weight: 700;
    color: var(--grey);
}



.flex_container:hover {
    border-left: 3px solid var(--orange);
}


.img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    background-size: cover;
    margin: 0px;
    padding: 0px;
}

.icon_image {
    width: 60px;
    margin: 0px;
    padding: 0px 0px;
    object-fit: contain;
    object-position: bottom;
    border-radius: 0px;
    padding-right: 6px;
}

.grid_container {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.flex_container {
    min-height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 3.5px solid #FDA307;
    margin: 25px 0px;
}

.spacing {
    height: 150px;
}


@media (max-width: 1880px) {

    .spacing {
        height: 0px;
    }
}

@media (max-width: 1240px) {
    .section_wrapper {
        width: 90%;
    }

    .spacing {
        height: 0px;
    }
}

@media (max-width: 900px) {
    .grid_container {
        grid-template-columns: 1fr 1fr;
    }

    .spacing {
        height: 0px;
    }
}

@media (max-width: 500px) {
    .grid_container {
        grid-template-columns: 1fr;
    }

    .spacing {
        height: 0px;
    }
}